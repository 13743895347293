var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('DxPopup',{attrs:{"show-title":true,"title":_vm.approvalStatus.label,"min-width":_vm.approvalPopup.minWidth,"width":_vm.approvalPopup.width,"min-height":_vm.approvalPopup.minHeight,"height":_vm.approvalPopup.height,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DxValidationGroup',{ref:"contentsValidationGroup"},[_c('div',[_c('div',[_c('table',{staticClass:"table_form line-bin"},[_c('colgroup',[_c('col',{staticStyle:{"width":"160px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v("결재 처리")])]),_c('td',{staticClass:"clearfix",attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.approvalStatus.label))])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v("결재 참고사항")])]),_c('td',{staticClass:"clearfix",attrs:{"colspan":"3"}},[_c('DxTextArea',{attrs:{"styling-mode":"outlined","width":330,"height":120},model:{value:(_vm.contents),callback:function ($$v) {_vm.contents=$$v},expression:"contents"}},[_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":"설명은 필수입니다."}})],1)],1)],1)])])]),_c('div',{attrs:{"id":"warning-div"}},[_c('i',{staticClass:"dx-icon-info"}),_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.description))])])])])])]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
				elementAttr: {
					class: 'default filled txt_S medium',
				},
				text: '저장',
				width: '120',
				height: '40',
				useSubmitBehavior: true,
				onClick: () => {
					_vm.onSave();
				},
			}}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
				elementAttr: {
					class: 'white filled txt_S medium',
				},
				text: '취소',
				width: '120',
				height: '40',
				onClick: () => {
					_vm.closeModal();
				},
			}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }