<!--
  PACKAGE_NAME : src\pages\ewm\approval\integration\popup
  FILE_NAME : modal-page-approval
  AUTHOR : devyoon91
  DATE : 2024-05-30
  DESCRIPTION :
-->
<template>
	<div class="container">
		<DxPopup
			:show-title="true"
			:title="approvalStatus.label"
			:min-width="approvalPopup.minWidth"
			:width="approvalPopup.width"
			:min-height="approvalPopup.minHeight"
			:height="approvalPopup.height"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			:visible="isOpen"
			@hiding="closeModal"
		>
			<template #content>
				<DxValidationGroup ref="contentsValidationGroup">
					<div>
						<div>
							<table class="table_form line-bin">
								<colgroup>
									<col style="width: 160px" />
									<col style="width: auto" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">
											<label for="label5">결재 처리</label>
										</th>
										<td colspan="3" class="clearfix">{{ approvalStatus.label }}</td>
									</tr>
									<tr>
										<th scope="row">
											<label for="label5">결재 참고사항</label>
										</th>
										<td colspan="3" class="clearfix">
											<DxTextArea v-model="contents" styling-mode="outlined" :width="330" :height="120">
												<DxValidator>
													<DxRequiredRule message="설명은 필수입니다." />
												</DxValidator>
											</DxTextArea>
										</td>
									</tr>
								</tbody>
							</table>
							<div id="warning-div">
								<i class="dx-icon-info"></i>
								<label for="label5">{{ description }}</label>
							</div>
						</div>
					</div>
				</DxValidationGroup>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="true"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: '저장',
					width: '120',
					height: '40',
					useSubmitBehavior: true,
					onClick: () => {
						onSave();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="true"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: '취소',
					width: '120',
					height: '40',
					onClick: () => {
						closeModal();
					},
				}"
			/>
		</DxPopup>
	</div>
</template>

<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import {DxValidationGroup} from "devextreme-vue/validation-group";

export default {
	components: {DxValidationGroup, DxRequiredRule, DxValidator, DxPopup, DxToolbarItem, DxTextArea },
	props: {
		isOpen: {
			default: false,
			type: Boolean,
		},
    approvalStatus: {
      default: () => {},
      type: Object,
    },
		description: {
			default: '',
			type: String,
		},
    contentsBox: {
      default: '',
      type: String,
    }
	},
	data() {
		return {
			approvalPopup: {
				minWidth: 550,
				width: 550,
				minHeight: 400,
				height: 400,
			},
			contents: '',
			instance: null,
		};
	},
	computed: {},
	methods: {
		onSave() {
			if (!this.$refs.contentsValidationGroup.instance.validate().isValid) return;
      const contents = this.contents.trim();
			this.$emit('saveModal', {
        contents: contents,
        status: this.approvalStatus.value,
      });
		},
		closeModal() {
			this.$emit('closeModal');
		},
    /**
     * @description 내용 초기화
     */
    cleanContents() {
      this.contents = '';
    },
	},
	created() {
  },
	mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.page-sub-box {
		padding: 5px;
	}

	#warning-div {
		margin-top: 20px;
		padding: 18px 20px 18px 15px;
		background-color: #f9f9f9;
	}
}
</style>
